import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  profileIconContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  profileText: {
    marginLeft: theme.spacing(2.5),
    flexDirection: "column",
  },
  memberButtonBorder: {
    borderLeft: `1px solid ${theme.palette.secondary.light}`,
    height: theme.spacing(7),
    paddingLeft: theme.spacing(1),
  },
  memberButton: {
    color: "white",
    padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
    backgroundColor: theme.palette.success.contrastText,
    borderRadius: theme.spacing(1),
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  admitButton: {
    padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
    borderRadius: theme.spacing(1),
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

export default useStyles;
