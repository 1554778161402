import { Grid, Typography } from "@mui/material";
import { Profile } from "@readyfit-common/models";
import { Member } from "src/store/reducers/organizations/organizationsApiSlice";
import useStyles from "./AthleteIcon.style";

interface AthleteIconProps {
  profile: Profile | Member;
  customStyles?: string;
}

const AthleteIcon = ({ profile, customStyles }: AthleteIconProps) => {
  const classes = useStyles();
  return (
    <Grid item className={`${classes.profileIcon} ${customStyles}`}>
      <Typography variant="caption" textTransform={"uppercase"} color={"white"}>
        {profile?.firstName?.[0]}
      </Typography>
    </Grid>
  );
};

export default AthleteIcon;
