import { Grid, Paper, Typography } from "@mui/material";
import { Profile, TestScore, isCardio } from "@readyfit-common/models";
import { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useHistory, useRouteMatch } from "react-router-dom";
import ResultsApprovalCard from "src/grading/components/ResultsApprovalCard/ResultsApprovalCard";
import { ResultsApprovalCardio } from "src/grading/components/ResultsApprovalCardioCard/ResultsApprovalCardioCard";
import { ResultsRepsCard } from "src/grading/components/ResultsRepsCard/ResultsRepsCard";
import { ResultsTimeCard } from "src/grading/components/ResultsTimeCard/ResultsTimeCard";
import ResultsStepper from "src/grading/components/Stepper/ResultsStepper/ResultsStepper";
import GradeTestLayout from "src/grading/layout/GradeTestLayout";
import {
  isPassFailGradeInCertifiedTest,
  isRepGradeInCertifiedTest,
  isTimeGrade,
} from "src/grading/models";
import { useLazyGetScoreByIdQuery } from "src/store/reducers/scores/scoresApiSlice";
import { useLazyGetUserByIdQuery } from "src/store/reducers/users/usersApiSlice";
import theme from "src/theme";
import useStyles from "./CertifiedTest.styles";

// eslint-disable-next-line react-hooks/rules-of-hooks

export function CertifiedTest() {
  const classes = useStyles();
  const [loadState, setLoadState] = useState(
    "NEW" as "NEW" | "LOADING" | "DONE",
  );
  const match = useRouteMatch<{ userId: string; testId: string }>();
  const [getScoreById] = useLazyGetScoreByIdQuery();
  const [getUserById] = useLazyGetUserByIdQuery();
  const [userProfile, setUserProfile] = useState<Profile | undefined | null>(
    undefined,
  );
  const [graderProfile, setGraderProfile] = useState<Profile | undefined>(
    undefined,
  );
  const [testScore, setTestScore] = useState<any | null>(null);

  const history = useHistory();

  useEffect(() => {
    setLoadState("NEW");
  }, []);

  if (loadState === "NEW") {
    setLoadState("LOADING");
    getScoreById(match.params.testId)
      .unwrap()
      .then(async (data) => {
        try {
          setTestScore(data.testScore);
          const { profile } = await getUserById(data.testScore.userId).unwrap();
          const { profile: grader } = await getUserById(
            data.testScore.graderId,
          ).unwrap();
          setUserProfile(profile);
          setGraderProfile(grader);
          setLoadState("DONE");
        } catch (error: any) {
          alert(error?.data?.message);
          history.goBack();
        }
      })
      .catch((error) => {
        alert(error?.data?.message);
        history.goBack();
      });
  }
  const stepper = () => {
    return (
      testScore && (
        <Grid
          // container
          lg={12}
          border={`1px solid #B9B9B9`}
          borderRadius={2}
          sx={{ height: "fit-content" }}
        >
          <Grid
            container
            flexDirection={"column"}
            sx={{ position: "relative" }}
          >
            <Grid
              container
              p={4}
              bgcolor={"#F0F0F0"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography variant="h5">Certified</Typography>
            </Grid>
            <ResultsStepper
              testScore={testScore}
              graderProfile={graderProfile}
            />
          </Grid>
        </Grid>
      )
    );
  };

  return loadState !== "DONE" ? (
    <Spinner
      animation="border"
      style={{
        left: "50%",
        top: "50%",
        position: "absolute",
        marginLeft: -8,
      }}
    />
  ) : (
    <GradeTestLayout stepperContent={stepper()}>
      <Grid container lg={12}>
        <Grid item xs={12} mt={1}>
          <Typography variant="h2">Test Results</Typography>
        </Grid>
        {/* Overview Container */}

        <Grid container item flex={1}>
          <Grid
            mt={5}
            mb={2}
            container
            xs={11.5}
            padding={5}
            bgcolor={theme.palette.primary.light}
            borderRadius={2}
            component={Paper}
          >
            <Grid item xs={5}>
              <div className={classes.profileIconContainer}>
                <div className={classes.profileIcon} />
                <div className={classes.profileText}>
                  <Typography variant="subtitle1">Name</Typography>
                  <Typography variant="h4" fontWeight={"bold"}>
                    {userProfile?.lastName}, {userProfile?.firstName}
                  </Typography>
                </div>
              </div>
            </Grid>

            <Grid item xs={5}>
              <div className={classes.profileIconContainer}>
                <Grid className={classes.profileText} padding={2}>
                  <Typography variant="subtitle1">Unit</Typography>
                  <Typography variant="h4" fontWeight={"bold"}>
                    {userProfile?.branchOfService ?? "N/A"}
                  </Typography>
                </Grid>
              </div>
            </Grid>

            <Grid container item xs={2} alignItems={"center"}>
              <Button
                className={classes.regradeButton}
                variant={"outlined"}
                color="primary"
                onClick={() =>
                  history.push(
                    `/grading/tests/${match.params.userId}/${match.params.testId}`,
                  )
                }
              >
                Re-Grade
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {(testScore as TestScore).exercises.map((grade: any, idx: any) => {
          console.log("metadata", grade?.metadata);
          if (isRepGradeInCertifiedTest(grade.metadata)) {
            return (
              <ResultsRepsCard
                key={idx}
                {...grade}
                fileKey={grade.metadata.fileKey as string}
                step={idx}
                testScore={testScore}
              />
            );
          } else if (isTimeGrade(grade.metadata)) {
            return (
              <ResultsTimeCard
                key={idx}
                {...grade}
                fileKey={grade.metadata.fileKey as string}
                step={idx}
                testScore={testScore}
              />
            );
          } else if (
            isCardio(grade.metadata) &&
            grade.userResult?.name !== "Body Composition"
          ) {
            return (
              <ResultsApprovalCardio
                key={idx}
                {...grade}
                fileKey={grade.metadata.fileKey as string}
                step={idx}
                testScore={testScore}
              />
            );
          } else if (
            grade?.metadata &&
            isPassFailGradeInCertifiedTest(grade.metadata)
          ) {
            return (
              <ResultsApprovalCard
                key={idx}
                {...grade}
                fileKey={grade.fileKey as string}
                step={idx}
                testScore={testScore}
              />
            );
          } else {
            return <></>;
          }
        })}
      </Grid>
    </GradeTestLayout>
  );
}
