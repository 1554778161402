import { Grid } from "@mui/material";
import {
  ExerciseResultMetadata,
  Profile,
  isBodyComp,
  isCardio,
} from "@readyfit-common/models";
import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useRouteMatch } from "react-router-dom";
import { GradeTestResult } from "src/grading/components/GradeTestResult/GradeTestResult";
import GradingHeader from "src/grading/components/GradingHeader/GradingHeader";
import ProgressStepper from "src/grading/components/Stepper/ProgressStepper/ProgressStepper";
import { isPassFailType } from "src/grading/components/Stepper/RepsStepper/StepperInput";
import GradeTestLayout from "src/grading/layout/GradeTestLayout";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  resetGradingSlice,
  selectGradingStep,
  selectGradingStepMetadata,
  selectGradingTest,
  selectGradingTestMetadata,
  setGradingStep,
  setGradingStepMetadata,
  setGradingTestMetadata,
  setTest,
} from "src/store/reducers/grading/gradingSlice";
import {
  TestScore,
  useLazyGetScoreByIdQuery,
  useScoreTestMutation,
} from "src/store/reducers/scores/scoresApiSlice";
import {
  ExerciseFormat,
  TestStepDefinitionFormat,
  useLazyGetTestDefinitionByIdQuery,
} from "src/store/reducers/tests/testsApiSlice";
import { useLazyGetUserByIdQuery } from "src/store/reducers/users/usersApiSlice";
import { GradeTestReview } from "../../components/GradeTestReview/GradeTestReview";
import { GradeTestExcercise } from "../../gradeTestExercise";
import { GradeTestOverview } from "../../gradeTestOverview";
import {
  ApprovalGradeResult,
  GradeResult,
  isApprovalGrade,
  isRepGrade,
  isTimeGrade,
} from "../../models/grading";

export function GradeTest(props: never) {
  const match = useRouteMatch<{ userId: string; testId: string }>();
  const [user, setUser] = useState<Profile | undefined | null>(undefined);
  const [graderProfile, setGraderProfile] = useState<Profile | undefined>(
    undefined,
  );
  const step = useAppSelector(selectGradingStep);
  const testMetadata = useAppSelector(selectGradingTestMetadata);
  const [loadState, setLoadState] = useState("NEW");
  const [gradeResult, setGradeResult] = useState<GradeResult[]>([]);

  const [getScoreById] = useLazyGetScoreByIdQuery();
  const [getUserById] = useLazyGetUserByIdQuery();
  const [getTestDefinitionById] = useLazyGetTestDefinitionByIdQuery();
  const [scoreTest, { isLoading: testScoreLoading }] = useScoreTestMutation();
  const stepMetadata = useAppSelector(selectGradingStepMetadata);
  const dispatch = useAppDispatch();
  const test = useAppSelector(selectGradingTest);
  const [testScore, setTestScore] = useState<any>();
  const [overallNotes, setOverallNotes] = useState<string | undefined>();

  useEffect(() => {
    const getStepMetadata = () => {
      let stepMetadata = testMetadata?.steps.find(
        (sm: TestStepDefinitionFormat) => {
          if (sm.choices && sm.choices?.length > 0) {
            const choiceFound = sm.choices.find((choice) => {
              return choice.exerciseId === test?.exercises[step].exerciseId;
            });
            return choiceFound;
          } else {
            return sm.exerciseId === test?.exercises[step].exerciseId;
          }
        },
      );

      if (stepMetadata?.choices) {
        stepMetadata = stepMetadata.choices.find((choice: ExerciseFormat) => {
          return choice.exerciseId === test?.exercises[step].exerciseId;
        });
      }
      dispatch(setGradingStepMetadata(stepMetadata));
    };
    if (step > -1 && test && step < test.exercises.length) {
      getStepMetadata();
    }
  }, [step, testMetadata, test?.exercises, dispatch, test]);

  useEffect(() => {
    return () => {
      // cleanup
      dispatch(resetGradingSlice());
    };
  }, [dispatch]);

  const onStepGrade = (stepGrade: GradeResult, stepOverride?: number) => {
    if (test !== undefined) {
      const results: GradeResult[] = [...gradeResult];
      const stepVal = stepOverride ?? step;

      if (isRepGrade(stepGrade) && stepGrade.graderCount !== undefined) {
        const userReps = stepGrade.userResult.userReps
          ? stepGrade.userResult.userReps?.toString() ?? "0"
          : stepGrade.userResult.reps?.toString() ?? "0";
        if (userReps === "0") {
          stepGrade.accuracyScore = 0;
        } else {
          stepGrade.accuracyScore =
            Math.round((stepGrade.graderCount / parseInt(userReps)) * 100) /
            100;
        }
      } else if (
        isApprovalGrade(stepGrade) &&
        stepGrade.approved !== undefined
      ) {
        // This is me not trusting type coercion
        stepGrade.accuracyScore = stepGrade.approved ? 1 : 0;
      }

      if (results.length <= stepVal) {
        results.push(stepGrade);
      } else {
        results[stepVal] = stepGrade;
      }

      setGradeResult(results);
    }
  };

  const onTestCertify = async (
    results: GradeResult[],
    uid: string,
    certified: "true" | "false" | "pending" = "true",
  ) => {
    if (test !== undefined) {
      const certifiedTestResult: TestScore = { ...test, certified };
      certifiedTestResult.exercises = gradeResult.map((grade, idx) => {
        const exercise = certifiedTestResult.exercises.find(
          (exercise) => exercise.exerciseId === grade.userResult.exerciseId,
        );

        let metadata: ExerciseResultMetadata = {
          ...grade.userResult,
          graderNotes: grade.notes,
          discrepancies: grade.discrepancies?.toString(),
          exceededRestTime: grade.exceededRestTime ?? false,
        };

        if (isRepGrade(grade)) {
          metadata = {
            ...metadata,
            reps: grade.graderCount ?? 0,
            userReps: grade.userResult.userReps
              ? grade.userResult.userReps
              : grade.userResult.reps,
            graderNotes: grade.userResult.graderNotes,
          };
        } else if (isTimeGrade(grade)) {
          metadata = {
            ...metadata,
            reps: `${
              grade.graderTime?.minutes.toString().padStart(2, "0") || "00"
            }:${grade.graderTime?.seconds.toString().padStart(2, "0") || "00"}`,
            userReps: grade.userResult.userReps
              ? grade.userResult.userReps
              : grade.userResult.reps,
            graderNotes: grade.userResult.graderNotes,
          };
        } else if (isApprovalGrade(grade)) {
          if (isBodyComp(grade.userResult)) {
            metadata = {
              ...metadata,
              score: grade.approved ? "Pass" : "Fail",
              graderNotes: grade.userResult.graderNotes,
            };
          } else if (isCardio(grade.userResult)) {
            metadata.graderNotes = grade.userResult.graderNotes;
            if (grade.discrepancies?.length > 0) {
              metadata.score = 0;
            }
          } else if (isPassFailType(grade.userResult.exerciseType)) {
            metadata = {
              ...metadata,
              reps: undefined,
              graderNotes: grade.userResult.graderNotes,
              passFail: grade.approved,
            };
          }
        }
        return {
          id: exercise?.id as string,
          exerciseId: exercise?.exerciseId as string,
          score: exercise?.score as string,
          metadata,
          fileKey: exercise?.fileKey as string,
        };
      });

      certifiedTestResult.overallNotes = overallNotes;

      // Call scoreTest with this payload
      const scoreTestResponse: any = await scoreTest({
        scoreId: certifiedTestResult.testResultId,
        certifiedTestResult,
      });

      if (scoreTestResponse?.data) {
        setTestScore(scoreTestResponse.data);
      }

      if (scoreTestResponse?.data?.graderId) {
        const { profile: grader } = await getUserById(
          scoreTestResponse?.data?.graderId,
        ).unwrap();
        setGraderProfile(grader);
      }
    }
  };

  const changeStep = (newStep: number) => {
    if (test !== undefined) {
      const nextStep = test.exercises[newStep];
      if (nextStep !== undefined) {
        if (isBodyComp(nextStep.metadata)) {
          // Skip rendering body comp steps
          dispatch(setGradingStep(newStep + (newStep > step ? 1 : -1)));
          onStepGrade(
            {
              userResult: nextStep.metadata,
              approved: true,
              notes: "",
            } as ApprovalGradeResult,
            newStep,
          );
          return;
        }
      }
      dispatch(setGradingStep(newStep));
    }
  };

  if (loadState === "NEW") {
    setLoadState("LOADING");
    getScoreById(match.params.testId)
      .unwrap()
      .then(async (data) => {
        dispatch(setTest(data.testScore));
        const { testDefinition } = await getTestDefinitionById({
          id: data.testScore.testId,
          version: data.testScore.testVersion,
        }).unwrap();
        dispatch(setGradingTestMetadata(testDefinition));
        const { profile } = await getUserById(data.testScore.userId).unwrap();
        setUser(profile);
        setLoadState("DONE");
      });
  }

  const stepper = () => {
    return (
      test && (
        <Grid
          // container
          lg={12}
          border={`1px solid #B9B9B9`}
          borderRadius={2}
          sx={{ height: "fit-content" }}
        >
          <ProgressStepper
            test={test}
            changeStep={changeStep}
            onTestCertify={onTestCertify}
            gradeResult={gradeResult}
            stepMetadata={
              step > -1 && step < test.exercises.length
                ? stepMetadata
                : undefined
            }
            onGrade={onStepGrade}
            currentGrade={
              gradeResult.length >= step ? gradeResult[step] : undefined
            }
            prevEnd={
              step >= 1 &&
              step < test.exercises.length &&
              !isBodyComp(
                test.exercises[step - 1].metadata as ExerciseResultMetadata,
              )
                ? (test.exercises[step - 1].metadata?.endTime as number)
                : null
            }
            testScore={testScore as TestScore}
            graderProfile={graderProfile}
            overallNotes={overallNotes}
            setOverallNotes={setOverallNotes}
          />
        </Grid>
      )
    );
  };

  if (loadState !== "DONE") {
    return (
      <Spinner
        animation="border"
        style={{
          left: "50%",
          top: "50%",
          position: "absolute",
          marginLeft: -8,
        }}
      />
    );
  } else if (test === undefined || user === undefined || user === null) {
    return <div>An error occurred. Please try again.</div>;
  } else {
    return (
      test &&
      testMetadata && (
        <GradeTestLayout stepperContent={stepper()}>
          <GradingHeader />

          {/* Recording Column */}
          <Grid container lg={12}>
            <Grid item lg={step === -1 ? 10 : 12}>
              {step === -1 && (
                <GradeTestOverview
                  test={test}
                  userProfile={user}
                  testMetaData={testMetadata}
                />
              )}
              {step >= 0 && step < test.exercises.length && (
                <GradeTestExcercise
                  key={step}
                  result={test.exercises[step]}
                  prevEnd={
                    step >= 1 &&
                    !isBodyComp(
                      test.exercises[step - 1]
                        .metadata as ExerciseResultMetadata,
                    )
                      ? (test.exercises[step - 1].metadata?.endTime as number)
                      : null
                  }
                  onGrade={onStepGrade}
                  currentGrade={
                    gradeResult.length >= step ? gradeResult[step] : undefined
                  }
                  userScore={
                    (test?.exercises.length ?? -1) >= step
                      ? test?.exercises[step]
                      : undefined
                  }
                  stepMetadata={stepMetadata}
                />
              )}
              {step === test.exercises.length && (
                <GradeTestReview
                  grades={gradeResult}
                  setStep={(newStep) => dispatch(setGradingStep(newStep))}
                  step={step}
                  onTestCertify={onTestCertify}
                  testScore={testScore}
                  testScoreLoading={testScoreLoading}
                />
              )}

              {test && step > test.exercises.length && (
                <GradeTestResult
                  grades={gradeResult}
                  test={test}
                  setStep={(newStep) => dispatch(setGradingStep(newStep))}
                  userProfile={user}
                  testScore={testScore}
                />
              )}
            </Grid>
          </Grid>
        </GradeTestLayout>
      )
    );
  }
}
