import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  actionButton: {
    color: "white",
    padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
    backgroundColor: theme.palette.success.contrastText,
    borderRadius: theme.spacing(1),
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

export default useStyles;
