import { Add, Remove } from "@mui/icons-material";
import { Button, IconButton, Link, List, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  selectShowDrawer,
  toggleDrawer,
} from "src/store/reducers/navigation/navigationSlice";
import useStyles from "./AppDrawer.styles";
import NavigationLink from "./NavigationLink";
import { NavLink, bottomLinks, standardLinks } from "./links";

const Links = () => {
  const showDrawer = useAppSelector(selectShowDrawer);
  const classes = useStyles(showDrawer)();
  return (
    <List
      dense
      component={"nav"}
      className={!showDrawer ? classes.nav : undefined}
    >
      {standardLinks.map((link: NavLink, i: number) => (
        <NavigationLink key={`${link.name}-${i}`} link={link} />
      ))}
    </List>
  );
};

const BottomLinks = () => {
  const showDrawer = useAppSelector(selectShowDrawer);
  const classes = useStyles(showDrawer)();
  return (
    <List dense component={"nav"} className={classes.bottomLinks}>
      {bottomLinks.map((link: NavLink, i: number) => (
        <NavigationLink key={`${link.name}-${i}`} link={link} />
      ))}
    </List>
  );
};

const AppDrawer = () => {
  const theme = useTheme();
  const showDrawer = useAppSelector(selectShowDrawer);
  const classes = useStyles(showDrawer)();
  const dispatch = useAppDispatch();

  return (
    <nav className={classes.root}>
      <IconButton
        className={classes.toggleButton}
        onClick={() => {
          dispatch(toggleDrawer());
        }}
      >
        {showDrawer ? (
          <Remove style={{ fill: theme.palette.secondary.light }} />
        ) : (
          <Add style={{ fill: theme.palette.secondary.light }} />
        )}
      </IconButton>

      {showDrawer ? (
        <>
          <img
            src="/logo_white_background.png"
            style={{
              objectFit: "contain",
              width: "110px",
              height: "56px",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: theme.spacing(7),
              marginBottom: theme.spacing(2),
            }}
            alt="readyfit-logo"
          />
          <Button
            variant="outlined"
            style={{ marginBottom: theme.spacing(7) }}
            onClick={() => {
              window.open(process.env.REACT_APP_COACH_PORTAL_URL, "_blank");
            }}
          >
            {"Coach Login"}
          </Button>
        </>
      ) : (
        <img
          src="/mini_logo_white_background.png"
          alt="mini_logo_white_background"
          style={{
            objectFit: "contain",
            width: "56px",
            height: "26px",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: theme.spacing(7),
            marginBottom: theme.spacing(7),
          }}
        />
      )}

      <Links />

      <BottomLinks />
    </nav>
  );
};

export default AppDrawer;
