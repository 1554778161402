import {
  BaseQueryApi,
  FetchArgs,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { logOut, setCredentials } from "./reducers/auth/authSlice";
import { RootState } from "./rootStore";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    let {
      auth: { accessToken },
    } = getState() as RootState;
    if (!accessToken) {
      accessToken = JSON.parse(
        window.localStorage.getItem("readyFitAuth") || "{}",
      ).accessToken;
    }
    if (accessToken) {
      headers.set("authorization", `Bearer ${accessToken}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: object,
) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    let refreshToken = (api.getState() as RootState).auth.refreshToken;
    if (!refreshToken) {
      refreshToken = JSON.parse(
        window.localStorage.getItem("readyFitAuth") || "{}",
      ).refreshToken;
    }

    if (!refreshToken) return result;

    const refreshResult = await baseQuery(
      {
        url: "/api/account/refresh",
        method: "POST",
        body: { refreshToken },
      },
      api,
      extraOptions,
    );

    if (refreshResult?.data) {
      const refreshData = refreshResult.data as {
        success: boolean;
        data: { accessToken: string; refreshToken: string };
      };

      if (refreshData.success) {
        api.dispatch(setCredentials({ ...refreshData.data }));
        result = await baseQuery(args, api, extraOptions);
        return result;
      } else {
        api.dispatch(logOut({}));
      }
    } else {
      api.dispatch(logOut({}));
    }
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
  tagTypes: ["Users", "Members", "Scores", "OrganizationRecruitmentProcess"],
});
