import { AddCircle, RemoveCircleOutline } from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  Grid,
  Slider,
  Stack,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import { useCallback, useEffect, useState } from "react";
import { DisplayTimeFromSeconds, getSecondsFromTime } from "src/grading/utils";
import { TestStepDefinitionFormat } from "src/store/reducers/tests/testsApiSlice";
import useStyles from "./StepperInput.styles";

export const isTimeExercise = (exerciseType: string | undefined) => {
  if (!exerciseType) {
    return false;
  }

  return (
    exerciseType === "minTime" ||
    exerciseType === "maxTime" ||
    exerciseType === "cardio"
  );
};

export const isMaxTimeExercise = (exerciseType: string | undefined) => {
  if (!exerciseType) {
    return false;
  }

  return exerciseType === "maxTime";
};

export const isTimeMilliseconds = (
  stepMetadata: TestStepDefinitionFormat | undefined,
) =>
  isTimeExercise(stepMetadata?.exerciseType) &&
  stepMetadata?.testing?.userEnteredResult?.type === "timeMilliseconds";

export const isRepsType = (exerciseType: string | undefined) => {
  if (!exerciseType) return false;

  return exerciseType === "reps";
};

export const isCardioType = (exerciseType: string | undefined) => {
  if (!exerciseType) return false;

  return exerciseType === "cardio";
};

export const isPassFailType = (exerciseType: string | undefined) => {
  if (!exerciseType) return false;

  return exerciseType === "passFail";
};

const StyledButton = styled(Button)(({ theme }: { theme: Theme }) => ({
  borderColor: theme.palette.secondary.light,
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
    borderColor: theme.palette.primary.main,
  },
}));

const StyledInput = styled(TextField)(({ theme }: { theme: Theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 0,
      borderColor: theme.palette.secondary.light,
    },
    "&:hover fieldset": {},
    "&.Mui-focused fieldset": {},
    "& input": {
      textAlign: "center",
      width: 60,
    },
  },
}));

interface StringStepperInputProps {
  placeholder: string;
  value: string | undefined;
  isDecrementDisabled?: boolean;
  isIncrementDisabled?: boolean;
  onChange: (value: string | undefined) => void;
  onDecrement: () => void;
  onIncrement: () => void;
}

interface StringStepperSliderProps {
  placeholder: string;
  value: string | undefined;
  isDecrementDisabled?: boolean;
  isIncrementDisabled?: boolean;
  onChange: (value: string | undefined) => void;
  onDecrement: () => void;
  onIncrement: () => void;
  setRawValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  displayValue?: string;
  max: number;
  step?: number;
}

const StringStepperInput = ({
  placeholder,
  value,
  isDecrementDisabled,
  isIncrementDisabled,
  onChange,
  onDecrement,
  onIncrement,
}: StringStepperInputProps) => {
  return (
    <Grid container>
      <ButtonGroup>
        <StyledButton onClick={onDecrement} disabled={isDecrementDisabled}>
          <RemoveCircleOutline
            fontSize="small"
            style={{ fill: isDecrementDisabled ? "#AAA" : "#000" }}
          />
        </StyledButton>
        <StyledInput
          size="small"
          placeholder={placeholder}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          disabled={isDecrementDisabled && isIncrementDisabled}
        />
        <StyledButton onClick={onIncrement} disabled={isIncrementDisabled}>
          <AddCircle
            fontSize="small"
            style={{ fill: isIncrementDisabled ? "#AAA" : "#000" }}
          />
        </StyledButton>
      </ButtonGroup>
    </Grid>
  );
};

const StringStepperSlider = ({
  value,
  isDecrementDisabled,
  isIncrementDisabled,
  onChange,
  onDecrement,
  onIncrement,
  setRawValue,
  displayValue,
  max,
  step,
}: StringStepperSliderProps) => {
  const classes = useStyles();
  return (
    <>
      {/* <Grid container xs={12} justifyContent={"center"}> */}
      {/* </Grid> */}
      <Stack
        width={"100%"}
        spacing={2}
        direction="row"
        sx={{ mb: 1 }}
        alignItems="center"
      >
        {/* <IconButton
          className={classes.button}
          onClick={onDecrement}
          disabled={isDecrementDisabled}
        >
          <RemoveCircleOutline />
        </IconButton> */}
        <Slider
          size="small"
          value={value ? +value : 0}
          onChange={(_, value) => setRawValue(String(value))}
          onChangeCommitted={(_, value) => onChange(value.toString())}
          max={max}
          step={step}
          // className={`${classes.thumb} ${classes.rail}`}
          classes={{
            thumb: classes.thumb,
          }}
        />
        <Typography variant="subtitle1">{displayValue || value}</Typography>
        {/* <IconButton
          className={classes.button}
          onClick={onIncrement}
          disabled={isIncrementDisabled}
        >
          <AddCircleOutline />
        </IconButton> */}
      </Stack>
    </>
  );
};

export interface IntegerStepperInputProps {
  placeholder: string;
  value: number | undefined;
  step?: number;
  onChange: (value: number | undefined) => void;
  isDecrementDisabled?: boolean;
  isIncrementDisabled?: boolean;
}

export interface IntegerStepperSliderProps extends IntegerStepperInputProps {
  max?: number;
}

export const IntegerStepperInput = ({
  value,
  step = 1,
  onChange,
  isDecrementDisabled,
  isIncrementDisabled,
  ...props
}: IntegerStepperInputProps) => {
  const [rawValue, setRawValue] = useState(value?.toString());
  const onRawChange = useCallback(
    (value: string | undefined) => {
      if (!value) {
        setRawValue(undefined);
        onChange(undefined);
      } else if (value.match(/^\d*$/)) {
        setRawValue(value);
        const parsedValue = parseInt(value);
        if (!isNaN(parsedValue)) {
          onChange(parsedValue);
        }
      }
    },
    [onChange],
  );
  useEffect(() => {
    if (Number(rawValue) !== value) {
      setRawValue(value?.toString());
    }
  }, [rawValue, value]);

  return (
    <StringStepperInput
      {...props}
      value={rawValue ?? ""}
      isDecrementDisabled={!value || isDecrementDisabled}
      isIncrementDisabled={isIncrementDisabled}
      onChange={onRawChange}
      onDecrement={() => onChange(value === undefined ? 0 : value - step)}
      onIncrement={() => onChange(value === undefined ? 1 : value + step)}
    />
  );
};

export const IntegerStepperSlider = ({
  value,
  step = 1,
  onChange,
  isDecrementDisabled,
  isIncrementDisabled,
  max,
  ...props
}: IntegerStepperSliderProps) => {
  const [rawValue, setRawValue] = useState(value?.toString());
  const onRawChange = useCallback(
    (value: string | undefined) => {
      if (!value) {
        setRawValue(undefined);
        onChange(undefined);
      } else if (value.match(/^\d*$/)) {
        setRawValue(value);
        const parsedValue = parseInt(value);
        if (!isNaN(parsedValue)) {
          onChange(parsedValue);
        }
      }
    },
    [onChange],
  );
  useEffect(() => {
    if (Number(rawValue) !== value) {
      setRawValue(value?.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <StringStepperSlider
      {...props}
      value={rawValue ?? ""}
      isDecrementDisabled={!value || isDecrementDisabled}
      isIncrementDisabled={isIncrementDisabled}
      onChange={onRawChange}
      onDecrement={() => onChange(value === undefined ? 0 : value - step)}
      onIncrement={() => onChange(value === undefined ? 1 : value + step)}
      setRawValue={setRawValue}
      max={max || 100}
    />
  );
};

export interface DecimalStepperInputProps {
  placeholder: string;
  value: number | undefined;
  precision: number;
  step: number;
  onChange: (value: number | undefined) => void;
}

export const DecimalStepperInput = ({
  value,
  precision,
  step,
  onChange,
  ...props
}: DecimalStepperInputProps) => {
  const [rawValue, setRawValue] = useState(value?.toString());
  const onRawChange = useCallback(
    (value: string | undefined) => {
      if (!value) {
        setRawValue(undefined);
        onChange(undefined);
      } else if (value.match(/^\d*.?\d*$/)) {
        setRawValue(value);
        const scale = 1 / precision;
        const parsedValue = Math.round(parseFloat(value) * scale) / scale;
        if (!isNaN(parsedValue)) {
          onChange(parsedValue);
        }
      }
    },
    [precision, onChange],
  );
  useEffect(() => {
    const scale = 1 / precision;
    const roundedValue =
      value === undefined ? undefined : Math.round(value * scale) / scale;
    if (Number(rawValue) !== roundedValue) {
      setRawValue(roundedValue?.toString());
    }
  }, [rawValue, value, precision]);

  return (
    <StringStepperInput
      {...props}
      value={rawValue ?? ""}
      onChange={onRawChange}
      isDecrementDisabled={!value || value < 0.001}
      onDecrement={() => onChange(value === undefined ? 0 : value - step)}
      onIncrement={() => onChange(value === undefined ? 0.1 : value + step)}
    />
  );
};

export interface DecimalStepperSliderProps {
  placeholder: string;
  value: number | undefined;
  precision: number;
  step: number;
  onChange: (value: number | undefined) => void;
  max?: number;
}

export const DecimalStepperSlider = ({
  value,
  precision,
  step,
  onChange,
  max,
  ...props
}: DecimalStepperSliderProps) => {
  const [rawValue, setRawValue] = useState(value?.toString());
  const onRawChange = useCallback(
    (value: string | undefined) => {
      if (!value) {
        setRawValue(undefined);
        onChange(undefined);
      } else if (value.match(/^\d*.?\d*$/)) {
        setRawValue(value);
        const scale = 1 / precision;
        const parsedValue = Math.round(parseFloat(value) * scale) / scale;
        if (!isNaN(parsedValue)) {
          onChange(parsedValue);
        }
      }
    },
    [precision, onChange],
  );
  useEffect(() => {
    const scale = 1 / precision;
    const roundedValue =
      value === undefined ? undefined : Math.round(value * scale) / scale;
    if (Number(rawValue) !== roundedValue) {
      setRawValue(roundedValue?.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, precision]);

  return (
    <StringStepperSlider
      {...props}
      value={rawValue ?? ""}
      onChange={onRawChange}
      isDecrementDisabled={!value || value < 0.001}
      onDecrement={() => onChange(value === undefined ? 0 : value - step)}
      onIncrement={() => onChange(value === undefined ? 0.1 : value + step)}
      max={max || 100}
      setRawValue={setRawValue}
      step={step}
    />
  );
};

export interface TimeStepperInputProps {
  placeholder: string;
  value: { minutes: number; seconds: number } | undefined;
  onChange: (value: { minutes: number; seconds: number } | undefined) => void;
  isDecrementDisabled?: boolean;
  isIncrementDisabled?: boolean;
  max?: number;
}

export const TimeStepperInput = ({
  value,
  onChange,
  isDecrementDisabled,
  isIncrementDisabled,
  ...props
}: TimeStepperInputProps) => {
  const [rawValue, setRawValue] = useState(value?.toString());
  const onRawChange = useCallback(
    (value: string | undefined) => {
      if (!value) {
        setRawValue(undefined);
        onChange(undefined);
      } else if (value.match(/^\d*:?\d*$/)) {
        if (
          value.length > 2 &&
          !value.includes(":") &&
          value.replaceAll("0", "").length > 0
        )
          value = `${value}:`;
        setRawValue(value);
        onChange(parseTime(value));
      }
    },
    [onChange],
  );
  const adjustValue = useCallback(
    (diff: number) => {
      const newValue = {
        minutes: value?.minutes ?? 0,
        seconds: value?.seconds ?? 0,
      };
      newValue.seconds += diff;
      while (newValue.seconds < 0) {
        --newValue.minutes;
        newValue.seconds += 60;
      }
      while (newValue.seconds >= 60) {
        ++newValue.minutes;
        newValue.seconds -= 60;
      }
      onChange(newValue);
    },
    [value, onChange],
  );
  useEffect(() => {
    const rawTime = rawValue === undefined ? undefined : parseTime(rawValue);
    if (
      rawTime?.minutes !== value?.minutes ||
      rawTime?.seconds !== value?.seconds
    ) {
      if (!value) {
        setRawValue(undefined);
      } else {
        const minutesString =
          value.minutes < 10 ? `0${value.minutes}` : `${value.minutes}`;
        const secondsString =
          value.seconds < 10 ? `0${value.seconds}` : `${value.seconds}`;
        setRawValue(
          value.minutes ? `${minutesString}:${secondsString}` : secondsString,
        );
      }
    }
  }, [rawValue, value]);

  return (
    <StringStepperInput
      {...props}
      value={rawValue ?? ""}
      isDecrementDisabled={
        (!value?.minutes && !value?.seconds) || isDecrementDisabled
      }
      isIncrementDisabled={isIncrementDisabled}
      onChange={onRawChange}
      onDecrement={() => adjustValue(-1)}
      onIncrement={() => adjustValue(1)}
    />
  );
};

export interface TimeStepperSliderProps extends TimeStepperInputProps {
  max?: number;
}

export const TimeStepperSlider = ({
  value,
  onChange,
  isDecrementDisabled,
  isIncrementDisabled,
  max,
  ...props
}: TimeStepperSliderProps) => {
  const [rawValue, setRawValue] = useState(value?.toString());
  const onRawChange = useCallback(
    (value: string | undefined) => {
      if (!value) {
        setRawValue(undefined);
        onChange(undefined);
      } else if (value.match(/^\d*:?\d*$/)) {
        const secondsToTime = DisplayTimeFromSeconds(+value || 0);
        setRawValue(value);
        onChange(parseTime(secondsToTime));
      }
    },
    [onChange],
  );
  const adjustValue = useCallback(
    (diff: number) => {
      const newValue = {
        minutes: value?.minutes ?? 0,
        seconds: value?.seconds ?? 0,
      };
      newValue.seconds += diff;
      while (newValue.seconds < 0) {
        --newValue.minutes;
        newValue.seconds += 60;
      }
      while (newValue.seconds >= 60) {
        ++newValue.minutes;
        newValue.seconds -= 60;
      }
      onChange(newValue);
    },
    [value, onChange],
  );
  useEffect(() => {
    const rawTime = rawValue === undefined ? undefined : parseTime(rawValue);

    if (
      rawTime?.minutes !== value?.minutes ||
      rawTime?.seconds !== value?.seconds
    ) {
      if (!value) {
        setRawValue(undefined);
      } else {
        const minutesString =
          value.minutes < 10 ? `0${value.minutes}` : `${value.minutes}`;
        const secondsString =
          value.seconds < 10 ? `0${value.seconds}` : `${value.seconds}`;

        const time = getSecondsFromTime(
          parseTime(
            value.minutes ? `${minutesString}:${secondsString}` : secondsString,
          ),
        );
        setRawValue(time.toString());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <StringStepperSlider
      {...props}
      value={rawValue ?? ""}
      isDecrementDisabled={
        (!value?.minutes && !value?.seconds) || isDecrementDisabled
      }
      isIncrementDisabled={isIncrementDisabled}
      onChange={onRawChange}
      onDecrement={() => adjustValue(-1)}
      onIncrement={() => adjustValue(1)}
      setRawValue={setRawValue}
      displayValue={DisplayTimeFromSeconds(rawValue ? +rawValue : 0)}
      max={max || 100}
    />
  );
};

export const parseTime = (value: string) => {
  const parts = value.split(":");
  const minutesString = parts.length > 1 ? parts[0] : undefined;
  const secondsString = parts.length > 1 ? parts[1] : parts[0];
  let minutes = parseInt(minutesString || "0");
  let seconds = parseInt(secondsString || "0");
  if (seconds > 59) {
    minutes += Math.floor(seconds / 60);
    seconds = seconds % 60;
  }
  return {
    minutes,
    seconds,
  };
};
