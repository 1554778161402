import { PersonOff } from "@mui/icons-material";
import { Button, CircularProgress, styled } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { usePermissions } from "src/grading/hooks/usePermissions";
import { permissions } from "src/grading/utils/permissionsHelpers";
import { useDisableUserMutation } from "src/store/reducers/users/usersApiSlice";
import useStyles from "./DisableUserButton.styles";

interface Props extends React.ComponentProps<typeof Button> {
  athleteId: string;
}

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.common.white,
}));

const DisableUserButton = ({ athleteId }: Props) => {
  const classes = useStyles();

  const { hasPermissions } = usePermissions();

  const [disableUser, { isLoading: disableUserLoading }] =
    useDisableUserMutation();

  const history = useHistory();

  const location = useLocation();

  const canDisableUser =
    hasPermissions(
      permissions.DISABLE_USER.action,
      permissions.DISABLE_USER.subject,
    ) ||
    hasPermissions(
      permissions.DISABLE_ORGANIZATION_MEMBER.action,
      permissions.DISABLE_ORGANIZATION_MEMBER.subject,
    );

  const isProfilePage =
    location.pathname.includes("/athletes/") &&
    !location.pathname.endsWith("/athletes");

  const handleDisableUser = () => {
    disableUser(athleteId)
      .unwrap()
      .then((res) => {
        alert(res.message);
        isProfilePage ? history.goBack() : window.location.reload();
      })
      .catch(() => alert("Error disabling user"));
  };

  return (
    canDisableUser && (
      <Button
        variant="contained"
        size="small"
        className={classes.actionButton}
        onClick={handleDisableUser}
      >
        {disableUserLoading ? (
          <StyledCircularProgress size={"1.4rem"} sx={{ fontSize: "14px" }} />
        ) : (
          <>
            Disable User
            <PersonOff />
          </>
        )}
      </Button>
    )
  );
};

export default DisableUserButton;
